import { LitElement, html } from 'lit';
import { spline } from './spline';
import TWEEN from '@tweenjs/tween.js';

import * as blobs2Animate from "blobs/v2/animate";

/**
 * Svg animated blob web component
 * @extends LitElement
 *
 * @property static - Tells the blob not to animate
 * @property intensity - How much the blob will warp during animation
 * @property points - How many points are in the blob
 * @property duration - How long the animation will last
 *
 */
class Blob extends LitElement {
	static properties = {
		static: {
			type: Boolean,
			attribute: true,
			reflect: true,
		},
		intensity: {
			type: Number,
			attribute: true,
			reflect: true,
		},
		rounding: {
			type: Number,
			attribute: true,
			reflect: true,
		},
		duration: {
			type: Number,
			attribute: true,
			reflect: true,
		},
		steps: {
			type: Number,
			reflect: true,
		},
		threshold: {
			type: Number,
			attribute: true,
			reflect: true,
		},
		color: {
			type: String,
			attribute: true,
			reflect: true,
		}
	};

	constructor() {
		super();
		this.static = false;
		this.intensity = 1;
		this.points = 6;
		this.duration = 4000;
		this.width = 100;
		this.height = 100;
		this.seed = null;
		this.timingFunction = "linear";
		this.blobBus = null;
		this.canvas = null;
		this.ctx = null;
		this.size = 100;
		this.animation = null;
		this.color = "#38C68B";
		this.blobOptions = {}
	}


	connectedCallback() {
		super.connectedCallback();

		this.canvas = document.createElement('canvas');
		this.ctx = this.canvas.getContext('2d');
		if (!this.seed) {
			this.seed = Math.random();
		}

		this.resize();
		console.log(this);
		console.log(this.points);


		this.blobOptions = {
			extraPoints: this.points,
			randomness: this.intensity,
			seed: this.seed,
			size: this.size,
		}

		this.animation = blobs2Animate.canvasPath();

		this.animation.transition({
			duration: 1,
			size: this.size,
			callback: this.loop.bind(this),
			blobOptions: this.blobOptions,

		})
		this.play();

		this.resizeObserver = new ResizeObserver(entries => {
			this.resize();
		});

		this.resizeObserver.observe(this);
	}

	firstUpdated() {
		this.resize();
	}

	resize() {
		const { width, height } = this.getBoundingClientRect();
		this.width = width;
		this.height = height;
		this.size = Math.min(width, height);

		if (this.size === 0) {
			const { width, height } = this.getBoundingClientRect();
			this.width = width;
			this.height = height;
			this.size = Math.min(width, height);
		}
		console.log(this);
		console.log(this.size);

		this.size = this.size;
		// Scale resolution to take into account device pixel ratio.
		this.canvas.width = this.size;
		this.canvas.height = this.size;

		this.blobOptions = {
			extraPoints: this.points,
			randomness: this.intensity,
			seed: this.seed,
			size: this.size,
		}


	}

	generateFrame() {
		this.blobOptions.seed = Math.random();
		return {
			duration: this.duration,
			timingFunction: this.timingFunction,
			seed: Math.random(),
			size: this.size,
			callback: this.loop.bind(this),
			blobOptions: this.blobOptions,
		}
	}

	play() {
		this.ctx.clearRect(0, 0, this.size, this.size);
		this.ctx.fillStyle = this.color;
		this.ctx.fill(this.animation.renderFrame());

		requestAnimationFrame(this.play.bind(this));
	}

	loop() {
		if (this.first)
		this.seed = Math.random();
		this.animation.transition(this.generateFrame());
		this.play();
	}

	createRenderRoot() {
		return this;
	}

	render() {
		return html`${this.canvas}`;
	}
}





class BlobBus extends LitElement {
	static properties = {
		blobs: {
			type: Array,
		},
		activeBlobs: {
			type: Array,
		},
	}

	constructor() {
		super();
		this.blobs = [];
		this.inViewBlobs = [];
		this.animator = new TWEEN();
	}

	connectedCallback() {
		super.connectedCallback();
		this.setupAnimationRunner();
	}

	setupAnimationRunner() {}


	attach(blob) {
		this.blobs.push(blob);
	}

	animateRunner() {
		this.inViewBlobs.forEach(blob => {
			blob.animate();
		});
	}
}


export default Blob;
