import Swiper, { Navigation, Pagination, Lazy, A11y } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
Swiper.use([Navigation, A11y]);

class CompanySlider {
	constructor() {
		this.sliderWrapper = document.querySelector(".featured-companies-block");
		this.slider = null;
		this.swiperInstance = null;
		if (this.sliderWrapper) {
			this.run();
		}
	}

	run() {
		this.sliderEl = this.sliderWrapper.querySelector(".swiper");

		this.swiperInstance = new Swiper(this.sliderEl, {
			spaceBetween: 40,
			direction: "horizontal",
			slidesPerView: "auto",
			centerInsufficientSlides: true,
			grabCursor: true,
			loop: false,
			navigation: {
				nextEl: ".swiper-button-next",
				prevEl: ".swiper-button-prev",
			},
			pagination: {
				el: ".swiper-pagination",
				type: "bullets",
				clickable: true,
			},
		});
	}
}

export default CompanySlider;
